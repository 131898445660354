<template>
  <div
    v-if="hasRebet || hasCancel || cashout"
    class="bet-details-actions">
    <div class="bet-details-actions-left">
      <Button
        v-if="hasRebet"
        variant="primary"
        @click="emitBetRebet">
        {{ store.getTranslation('general_rebet') }}
      </Button>
      <Button
        v-if="hasCancel"
        class="bet-details-actions__button"
        @click="emitBetCancel">
        {{ store.getTranslation('cancel') }}
      </Button>
    </div>

    <div class="bet-details-actions-right">
      <template v-if="cashout">
        <template v-if="!cashoutIncludesErrorCode">
          <Button
            :disabled="!cashout?.allowed"
            variant="secondary"
            class="bet-details-actions__cashout"
            @click="emitBetCashout">
            <span v-if="!cashout.processing">
              {{ store.getTranslation('cashout') }}
              {{ formatNumberWithCurrency(cashout.winnings.total, currency) }}
            </span>
            <Spinner
              v-else
              class="mini" />
          </Button>
        </template>
        <div
          v-else
          :title="getTranslation('cashout_unavailable')"
          class="bet-details-actions__cashout-unavailable">
          <Icon
            icon="n-i-lock-a"
            size="xl" />
          <span>{{ getTranslation('cashout_unavailable') }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import Button from '@/components/common/Button.vue';
import Spinner from '@/components/common/Spinner.vue';
import Icon from '@/components/common/Icon.vue';
import { useStore } from '@/stores/store';
import { formatNumberWithCurrency } from '@/utils/helpers';

const emit = defineEmits(['rebetBet', 'cancelBet', 'cashoutBet']);

const props = defineProps({
  hasRebet: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasCancel: {
    type: Boolean,
    required: false,
    default: false,
  },
  cashout: {
    type: [Object, null],
    required: false,
    default: () => null,
  },
  currency: {
    type: String,
    required: true,
  },
});

const store = useStore();
const { getTranslation } = store;

const emitBetRebet = (bet) => emit('rebetBet', bet);
const emitBetCancel = () => emit('cancelBet');
const emitBetCashout = () => emit('cashoutBet');

const errorCodes = [1001, 1004];
const cashoutIncludesErrorCode = computed(() => {
  const { cashout } = props;

  if (!cashout) return false;

  return cashout.codes.some((code) => errorCodes.includes(Number(code)));
});
</script>

<style lang="scss">
:root {
  --bet-details-actions-button-color: var(--primary);
}
</style>

<style scoped lang="scss">
.bet-details-actions {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--gap-16) 0 0 0;
  gap: var(--gap-16);

  &__button {
    font-size: 0.875rem;
    text-transform: uppercase;
    padding: var(--gap-12) var(--gap-base);
    color: var(--bet-details-actions-button-color);
    text-align: right;
    cursor: pointer;
  }

  &__cashout {
    background-color: var(--cashout-button-bg-color);
    color: var(--cashout-button-color);
    min-width: 9.375rem;
    font-weight: 500;
    overflow: hidden;
    border-color: var(--cashout-button-border-color);

    &.btn--disabled {
      opacity: 0.3;
    }

    &-unavailable {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--gap-base);
      padding: var(--gap-10) var(--gap-24);
      color: var(--text-primary-2);
      background-color: var(--button);
      border-radius: var(--border-radius-xs);
    }
  }
}
</style>
