import Decimal from 'decimal.js';
import { getBoostedBetslips } from '@/api/distribution.js';
import {
  constructClientIds,
  formatOdd,
  formatStakeValue,
  getIconClass,
  isMobile,
} from '@/utils/helpers.js';
import { useStore } from '@/stores/store.js';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { cloneDeep, has, orderBy } from 'lodash-es';

import { useBetslipStore } from '@/stores/BetslipStore.js';

import useSupplemental from '@/composables/supplemental/useSupplemental.js';
import { sendAcknowledgementMessage } from '@/services/sockets/calculationSocket.js';
import { formatBerryBetPlacement } from '@/services/formatters/berryFormatter.js';
import { getCustomBets } from '@/api/custom-bet.js';
import { getTicketType } from '@/services/bet-formatter.js';

export default function useBoostedBetslips({ betslipId } = {}) {
  const store = useStore();
  const betslipStore = useBetslipStore();

  const {
    boostedBetslips,
    boostedBetslipsList,
    boostedBetslipsEvents: events,
    boostedBetslipsData,
    boostedBetslipsCalculations,
  } = storeToRefs(store);

  const {
    getSupplemental,
    getMarketSupplementalName,
    getMarketOutcomeSupplementalName,
    getCompetitorSupplementalName,
  } = useSupplemental('overview');

  async function loadBoostedBetslips() {
    if (!store.config?.boostedBetslips) return;

    try {
      store.boostedBetslipsRequested = true;
      const response = await getBoostedBetslips(store.isLive ? '2' : '1,2', {
        clientIds: constructClientIds(),
        ...(store.config?.supplemental?.list
          ? { supplementalNameTypeIds: store.config.supplemental.list }
          : {}),
        numberOfBetslips: 15,
      });

      store.useOfferAdapter('BOOSTED_BETSLIPS_SETUP', response.data);
    } catch (error) {
      store.boostedBetslipsRequested = false;
      console.log('error', error);
    }
  }

  const numberOfBostedBetslips = computed(() => boostedBetslips.value?.size ?? 0);

  const boostedBetslipsForDisplay = computed(() =>
    orderBy(
      boostedBetslipsList.value.filter(
        (id) =>
          store.boostedBetslipsData.get(id) &&
          boostedBetslips.value?.get(id)?.bets?.every((bet) =>
            bet?.selections?.every((selection) => {
              const event = events.value.get(selection.eventId);
              const eventMarket = event?.markets?.get(selection.eventMarketId);
              const eventMarketOutcome = eventMarket?.outcomes?.get(selection.eventMarketOutcomeId);

              const metaMarket = store.markets.get(eventMarket.marketId);
              const metaOutcome = store.outcomes.get(eventMarketOutcome.outcomeId);

              const eStatus = has(event, 'status') ? event.status : true;
              const mStatus =
                (has(eventMarket, 'status') ? eventMarket.status : true) &&
                (has(metaMarket, 'status') ? metaMarket.status : true);
              const oStatus =
                (has(eventMarketOutcome, 'status') ? eventMarketOutcome.status : true) &&
                (has(metaOutcome, 'status') ? metaOutcome.status : true);

              return eStatus && mStatus && oStatus && eventMarketOutcome?.odds;
            }),
          ),
      ),
      (id) => {
        const betslip = boostedBetslips.value?.get(id);
        return store.isLive ? betslip.positionLive : betslip.positionPrematch;
      },
    ).slice(0, 12),
  );

  function getEventCompetitors(id) {
    const event = events.value.get(id);

    if ([2, 3].includes(event?.competitorType)) return [getSupplemental(event)];
    return orderBy(event?.competitors, ['ordinal']).map((competitor) =>
      getCompetitorSupplementalName(competitor),
    );
  }

  function getEventName(id) {
    return getEventCompetitors(id)?.join(' - ');
  }

  function formatEventStartsAt(date) {
    return dayjs(date).format('DD.MM. HH:mm');
  }

  function getTournamentName(tournamentId, tournamentRoundId) {
    const tournamentRound = store.tournamentRounds.get(tournamentRoundId);
    const tournament = store.tournaments.get(tournamentId);

    const tournamentRoundName =
      tournamentRound && tournamentRound?.name !== tournament?.name
        ? getSupplemental(tournamentRound)
        : '';

    return tournamentRoundName
      ? `${getSupplemental(tournament)} - ${tournamentRoundName}`
      : getSupplemental(tournament);
  }

  const firstBet = computed(() => boostedBetslips.value.get(betslipId.value)?.bets?.[0] ?? null);
  const selections = computed(() => {
    return Object.values(boostedBetslipsData.value.get(betslipId.value) ?? {});
  });

  const isBetBuilder = computed(() =>
    Object.values(selections.value ?? {})?.some((selection) => selection.type === 'bet_builder'),
  );

  async function formatSelectionsForBetslip(id) {
    const list = [];
    const data = {};

    const boostedBetslip = boostedBetslips.value.get(id);

    for (const bet of boostedBetslip.bets) {
      for (const selection of bet.selections) {
        const event = events.value.get(selection.eventId);
        const eventMarket = event.markets.get(selection.eventMarketId);
        const eventMarketOutcome = eventMarket.outcomes.get(selection.eventMarketOutcomeId);

        if (!event || !eventMarket || !eventMarketOutcome) continue;

        const metaMarket = store.markets.get(eventMarket.marketId);
        const metaOutcome = store.outcomes.get(eventMarketOutcome.outcomeId);

        const isBetBuilder = selection.type === 2;

        const constructSelectionIndex = () => {
          if (isBetBuilder) return `${event.id}/bet_builder`;
          return `${event.id}/${eventMarket.id}/${eventMarketOutcome.id}`;
        };

        const selectionIdx = constructSelectionIndex();

        const tournament = store.tournaments.get(event.tournamentId);
        const category = store.categories.get(tournament.categoryId);
        const sport = store.sports.get(category.sportId);

        const getTournamentIcon = () => {
          if (tournament?.code) return getIconClass(tournament?.code);
          return `flag flag-${category?.code?.toLowerCase() ?? 'int'}`;
        };

        if (!list.includes(selectionIdx)) {
          list.push(selectionIdx);
          data[selectionIdx] = {
            active: true,
            banker: selection.banker,
            id: selectionIdx,
            competitors: getEventCompetitors(event.id),
            eventCompetitors: [],
            type: isBetBuilder ? 'bet_builder' : 'regular',
            eventId: event.id,
            startsAt: event.startsAt,
            playStatus: event.playStatus,
            sportId: sport.id,
            metadata: event?.metadata ?? null,
            categoryId: category.id,
            categoryCode: category?.code ?? 'int',
            tournamentIcon: getTournamentIcon(),
            tournamentId: tournament.id,
            tournamentName: getTournamentName(tournament.id, event?.tournamentRoundId),
            game: event.playStatus === 2 ? 'LIVE' : 'PREMATCH',
            markets: new Map(),
            previousOdds: null,
            odds: eventMarketOutcome.odds,
            limits: {},
          };
        }

        data[selectionIdx].markets.set(`${eventMarket.id}/${eventMarketOutcome.id}`, {
          active: true,
          marketId: eventMarket.id,
          metaMarketId: eventMarket.marketId,
          outcomeId: eventMarketOutcome.id,
          metaOutcomeId: eventMarketOutcome.outcomeId,
          odds: eventMarketOutcome.odds,
          marketName:
            getSupplemental(eventMarket) ??
            getMarketSupplementalName(
              {
                ...eventMarket,
                supplementalNames: metaMarket?.supplementalNames ?? {},
                eventMarketName: metaMarket?.eventMarketName,
                name: metaMarket?.name,
              },
              event,
            ),
          outcomeName:
            getSupplemental(eventMarketOutcome) ??
            getMarketOutcomeSupplementalName(
              {
                ...eventMarketOutcome,
                supplementalNames: metaOutcome?.supplementalNames ?? {},
                eventMarketOutcomeName: metaOutcome?.eventMarketOutcomeName,
                name: metaOutcome?.name,
              },
              event,
              metaMarket,
            ),
        });
      }
    }

    for (const id of list) {
      const selection = data[id];

      if (selection.type === 'bet_builder') {
        const customBets = await getCustomBets(selection.eventId);
        store.sendOfferWorkerMessage('set-event-bet-builder-selections', {
          eventId: selection.eventId,
          selections: customBets?.selections ?? [],
        });

        data[id].odds =
          (await store.calculateBetBuilderOdds(data[id], customBets.selections)) || 10000;
      }
    }

    return {
      list,
      data,
    };
  }
  async function addToBetslip(id, stake) {
    const list = Object.keys(store.boostedBetslipsData.get(id) ?? {});

    betslipStore.selectionsList = list;
    betslipStore.selectionsData = list.reduce((acc, n) => {
      const data = cloneDeep(store.boostedBetslipsData.get(id)[n]);
      acc[n] = {
        ...data,
        metadata: events.value.get(data.eventId)?.metadata ?? null,
      };

      return acc;
    }, {});

    if (stake) betslipStore.updateStake(stake?.toFixed(2));
    if (isMobile) store.openBetslip();

    window.pushEventToGoogleTagManager('boosted_betslips_add_to_betslip');
  }

  async function requestCalculationForBoostedBetslips(id) {
    const { list, data } = await formatSelectionsForBetslip(id);
    if (!list.length) return;

    const berryFormatedSelectionsData = formatBerryBetPlacement({
      selections: data,
      stake: formatStakeValue((boostedBetslips.value?.get(id)?.bets?.[0]?.stake || 1)?.toString()),
      cachedIds: null,
      selectedTicketType: betslipStore.selectedTicketType,
      systems: null,
    });

    if (!berryFormatedSelectionsData?.betslip?.bets?.length) return;

    try {
      const response = await sendAcknowledgementMessage('calculaton:acknowledgement', {
        data: berryFormatedSelectionsData,
      });

      store.boostedBetslipsCalculations.set(id, response.data);
    } catch (error) {
      console.log('We caught an error calculating winnings for boosted betslips. Error:', error);
    }
  }

  function recalculateBoostedBetslips() {
    for (const id of store.boostedBetslipsList) {
      const data = formatBerryBetPlacement({
        selections: store.boostedBetslipsData.get(id),
        stake: formatStakeValue(
          (boostedBetslips.value?.get(id)?.bets?.[0]?.stake || 1)?.toString(),
        ),
        cachedIds: null,
        selectedTicketType: betslipStore.selectedTicketType,
        systems: null,
      });

      if (data?.betslip?.bets?.length)
        sendAcknowledgementMessage('calculaton:acknowledgement', {
          data,
        }).then((response) => store.boostedBetslipsCalculations.set(id, response.data));
    }
  }

  function getEvent(id) {
    return events.value.get(id);
  }

  const totalSelectionsOdds = computed(() =>
    formatOdd(
      selections.value.reduce((acc, n) => acc * +formatOdd(n.odds, 'decimal'), 1),
      store.oddFormat,
      1,
    ),
  );

  function calculateBonusPercentage(part, total) {
    return new Decimal(part)
      .div(total)
      .times(100)
      .toDecimalPlaces(0, Decimal.ROUND_HALF_UP)
      .toNumber();
  }

  const betslipBonus = computed(() => {
    const { winnings: calculation = {} } =
      (boostedBetslipsCalculations.value.size &&
        boostedBetslipsCalculations.value?.get(betslipId.value)) ||
      {};

    const { bets = [] } = calculation?.betslip ?? {};

    const { payinBonus, winningsBonus, winnings, stake } = bets.reduce(
      (acc, n) => {
        acc.winnings += +n.pWinnings.max.win;
        acc.stake += +n.payin.stake;

        acc.payinBonus += n.payin.bonuses.reduce((acc, n) => (acc += +n.amount), 0);
        acc.winningsBonus += n.pWinnings.max.bonuses.reduce((acc, n) => (acc += +n.amount), 0);

        return acc;
      },
      { stake: 0, winnings: 0, payinBonus: 0, winningsBonus: 0 },
    );

    const payinBonusPercentage = payinBonus ? calculateBonusPercentage(payinBonus, stake) : 0;
    const winningsBonusPercentage = winningsBonus
      ? calculateBonusPercentage(winningsBonus, winnings)
      : 0;

    if (payinBonusPercentage && winningsBonusPercentage) {
      return store.getTranslation('boosted_betslips.payin_and_winnings_bonus', {
        payinBonus: payinBonusPercentage,
        winningsBonus: winningsBonusPercentage,
      });
    }

    if (payinBonusPercentage)
      return store.getTranslation('boosted_betslips.payin_bonus', {
        payinBonus: payinBonusPercentage,
      });

    if (winningsBonusPercentage)
      return store.getTranslation('boosted_betslips.winnings_bonus', {
        winningsBonus: winningsBonusPercentage,
      });

    return '';
  });

  const betType = computed(() => getTicketType(firstBet.value ?? {})?.key);
  const betTypeTitle = computed(() => {
    if (isBetBuilder.value) return store.getTranslation('bet_builder');
    return store.getTranslation(betType.value);
  });

  return {
    loadBoostedBetslips,

    boostedBetslips,
    boostedBetslipsList,
    boostedBetslipsForDisplay,

    numberOfBostedBetslips,

    firstBet,
    selections,

    addToBetslip,
    requestCalculationForBoostedBetslips,
    recalculateBoostedBetslips,

    formatSelectionsForBetslip,

    getEvent,
    getEventName,
    formatEventStartsAt,

    totalSelectionsOdds,
    isBetBuilder,

    betTypeTitle,

    betslipBonus,
  };
}
