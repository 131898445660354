import Decimal from 'decimal.js';

export const isMobile = /iphone|ipod|android|ie|blackberry|fennec/.test(
  navigator.userAgent.toLowerCase(),
);

/**
 * Rounds a number to the provided number of decimals.
 *
 * @param {number} number - The number to round.
 * @param {number} [decimals=0] - The number of decimals to round to. Default is 0.
 * @returns {number} - The rounded number.
 */
const roundNumber = (number, decimals = 0) =>
  Math.round((number * 10 ** decimals) / 10 ** decimals);

/**
 * Calculates the greatest common divisor of two numbers.
 *
 * @param {number} a - The first number.
 * @param {number} b - The second number.
 * @returns {number} - The greatest common divisor of the two numbers.
 */
const greatestCommonDivisor = (a, b) => (b ? greatestCommonDivisor(b, a % b) : a);

export const convertToIntegerOdds = (odds, divider) =>
  new Decimal(odds ?? 0).dividedBy(divider).toDecimalPlaces(2);

export const convertDecimalOddsToInteger = (odds = 1) =>
  new Decimal(odds).times(10000).toDecimalPlaces(0).toNumber();

const convertToAmericanOdds = (odds) => {
  if (odds <= 1) return '+0';

  return odds >= 2
    ? `+${Math.round((odds - 1) * 100, 0)}`
    : `-${Math.round(Math.abs(100 / (odds - 1)))}`;
};

const convertToHongKongOdds = (odds) => {
  const hongKongOdd = odds <= 1 ? -1 * (1 / (odds - 1)) : odds - 1;

  return hongKongOdd.toFixed(2);
};

const convertToIndonesian = (odds) => {
  const indonesianOdd = odds > 2 ? odds - 1 : -1 / (odds - 1);

  return indonesianOdd.toFixed(2);
};

const convertToMalaysian = (odds) => {
  const malaysianOdd = odds >= 2 ? -1 / (odds - 1) : odds - 1;

  return malaysianOdd.toFixed(2);
};

const convertToFractionalOdds = (odds) => {
  const oddsMap = {
    '303/10000': '1/33',
    '143/1000': '1/7',
    '167/1000': '1/6',
    '111/500': '2/9',
    '143/500': '2/7',
    '333/1000': '1/3',
    '91/250': '4/11',
    '111/250': '4/9',
    '533/1000': '8/15',
    '571/1000': '4/7',
    '123/200': '8/13',
    '667/1000': '4/6',
    '727/1000': '8/11',
    '833/1000': '5/6',
    '909/1000': '10/11',
    '3333/1000': '10/3',
  };

  const closestOdd = [
    1.01, 1.02, 1.0303, 1.04, 1.1, 1.125, 1.143, 1.167, 1.2, 1.222, 1.25, 1.286, 1.3, 1.333, 1.364,
    1.4, 1.444, 1.5, 1.533, 1.571, 1.615, 1.667, 1.727, 1.8, 1.833, 1.909, 2.0, 2.1, 2.2, 2.25, 2.3,
    2.375, 2.5, 2.625, 2.75, 3.0, 3.2, 3.25, 3.4, 3.5, 3.75, 4.0, 4.333, 4.5, 5.0, 5.5, 6.0, 6.5,
    7.0, 7.5, 8.0, 8.5, 9.0, 9.5, 10.0, 11.0, 12.0, 13.0, 15.0, 17.0, 21.0, 26.0, 34.0, 41.0, 51.0,
    67.0, 101.0,
  ].reduce((a, b) => (Math.abs(b - odds) < Math.abs(a - odds) ? b : a));

  const closestValue = Math.abs(closestOdd - odds) < 1 ? closestOdd : odds;
  const decimals = String(closestValue).includes('.')
    ? String(closestValue).split('.')[1].length
    : 1;
  const multiplier = 10 ** decimals;
  const numerator = roundNumber((closestValue - 1) * multiplier, decimals);
  const denominator = greatestCommonDivisor(numerator, multiplier);
  const fraction = `${numerator / denominator}/${multiplier / denominator}`;

  return oddsMap[fraction] || fraction;
};

export const formatOdd = (odds, overwriteOddsType = '', divider = 10000) => {
  if (!odds) return;
  const oddsType = overwriteOddsType || window.oddsType;

  const formattedOdds = convertToIntegerOdds(odds, divider);
  switch (oddsType) {
    case 'american':
      return convertToAmericanOdds(formattedOdds);
    case 'fractional':
      return convertToFractionalOdds(formattedOdds);
    case 'hongkong':
      return convertToHongKongOdds(formattedOdds);
    case 'malaysian':
      return convertToMalaysian(formattedOdds);
    case 'indonesian':
      return convertToIndonesian(formattedOdds);
    default:
      return formattedOdds.toFixed(2);
  }
};

export const constructEndDate = (date = null, days = 0) =>
  dayjs(date ?? dayjs())
    ?.add(days, 'days')
    ?.set('hour', 23)
    ?.set('minute', 59)
    ?.set('second', 59)
    ?.format('YYYY-MM-DDTHH:mm:ss');

export const constructStartDate = (fromStartDay = false) => {
  const startDate = fromStartDay ? dayjs().startOf('day') : dayjs();
  return startDate.format('YYYY-MM-DDTHH:mm:ss');
};

export const formatStakeValue = (value) => {
  // Remove leading zeros if present
  const trimmedValue = value.replace(/^0+(?=\d)/, '');

  // Remove any non-numeric characters (allow dot or comma only)
  const sanitizedValue = trimmedValue.replace(/[^0-9.,]/g, '');

  // Replace any leading dots or commas with an empty string
  const dotValue = sanitizedValue.replace(/^[.,]+/, '');

  // Replace any remaining commas with dots (to standardize the decimal separator)
  const standardizedValue = dotValue.replace(/,/g, '.');

  // Ensure there is only one dot or comma (and up to two decimals)
  const regex = /^(\d*\.?\d{0,2}).*$/;

  const formattedValue = standardizedValue.replace(regex, '$1');

  return formattedValue.substring(0, 20);
};

export const normalizeAndRemoveDiacritics = (str) => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const formatNumberWithCurrency = (number = '', currency, languageCode) =>
  (+number || 0).toLocaleString(languageCode || window.languageCode || 'en', {
    style: 'currency',
    currency: currency || window.currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const constructClientIds = (id = null) => {
  let clientIds = {
    channel: isMobile ? 'mobile' : 'desktop',
  };

  const punterId = id || window.punter?.id || null;
  if (punterId) clientIds.punterId = punterId;

  return clientIds;
};

export const supportedOddFormats = {
  decimal: 'decimal_odds',
  american: 'american_odds',
  fractional: 'fractional_odds',
  hongkong: 'hongkong_odds',
  indonesian: 'indonesian_odds',
  malaysian: 'malaysian_odds',
};

export const formatNumberWoCurrency = (number, fractionDigits = 2) =>
  (+number || 0)?.toLocaleString('en', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

export const getIconClass = (code, { isLargeFlag = false } = {}) => {
  if (!code) return '';

  if (code.startsWith('flag')) {
    const [, flag] = code.split('-');

    return `flag ${isLargeFlag ? `flag-lg flag-lg-${flag}` : code.toLowerCase()}`;
  }

  return code.toLowerCase();
};
